import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import Swal from 'sweetalert2';

import './custom.css'

const Home = React.lazy(() => import('./components/Customer/Home'));
const AdminPortal = React.lazy(() => import('./components/Admin/AdminPortal'));
const MerchantPortal = React.lazy(() => import('./components/Merchant/MerchantPortal'));

const translateVersion = {
  "application-en": 8,
  "application-fr": 8
};

export default class App extends Component {

    constructor(props) {
        window.swal = Swal;
        Object.keys(translateVersion).map(function(k){
          if(!localStorage.getItem(k+'-ver')) localStorage[k+'-ver'] = translateVersion[k];
          if(translateVersion[k] >= localStorage[k+'-ver']){
            localStorage[k+'-ver'] = translateVersion[k];
            localStorage.removeItem(k);
          }
        });
        super(props);
    }

    static displayName = App.name;

    static formatNum = function(val, min, max) {
        if (!min) min = 2;
        if (!max) max = 2;
        if (!val) val = 0;
        return (parseFloat(val)).toLocaleString(
            'en-US', // leave undefined to use the visitor's browser
            // locale or a string like 'en-US' to override it.
            { minimumFractionDigits: min, maximumFractionDigits: max }
        );
    }

    render() {
        return (
            <React.Suspense fallback={false}>
                <Switch>
                    <Route path='/admin' component={AdminPortal} />
                    <Route path='/merchant' component={MerchantPortal} />
                    <Route path='/' component={Home} />
                </Switch>
            </React.Suspense>
        );
    }
}
